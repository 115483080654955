module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Francesco Menici - Pianista, Compositore, Arrangiatore","short_name":"Francesco Menici","description":"Francesco Menici è un pianista, tastierista, compositore e arrangiatore nato a Livorno nel 1984.","start_url":"/","background_color":"#ffffff","theme_color":"#4DB6AC","display":"standalone","icon":"src/images/icon.png","lang":"it","localize":[{"start_url":"/en/","lang":"en","name":"Francesco Menici - Pianist, Composer, Arranger","short_name":"Francesco Menici","description":"Francesco Menici is a pianist, keyboardist, composer, and arranger born in Livorno in 1984.\""}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0d297195a38be8aabefcd5a48581653b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
